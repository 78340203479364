import { Route, Routes, Navigate } from "react-router-dom";
import Register from "./views/auth/register/Register";
import Campaign from "./views/campaigns/Campaign";
import Home from "./views/home/Home";
import Profile from "./views/profile/Profile";
import VerifyEmail from "./views/auth/verify/VerifyEmail";
import VerifyPhone from "./views/auth/verify/VerifyPhone";
import RequestUnsubscribe from "./views/auth/unsubscribe/RequestUnsubscribe";
import Unsubscribe from "./views/auth/unsubscribe/Unsubscribe";
import Header from "./views/layout/Header";
import Login from "./views/auth/login/Login";
import { useAuth, useAuthenticatedRoute, useUnauthenticatedRoute } from "./utils/context/authContext";
import { QueryClient, QueryClientProvider } from "react-query";
import ForgotPassword from "./views/auth/forgot-password/ForgotPassword";
import ResetPassword from "./views/auth/reset-password/ResetPassword";
import CreatePassword from "./views/auth/create-password/CreatePassword";

function App() {
    const { authenticated, logout } = useAuth();

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                onError: (err) => {
                    if (authenticated && err.response?.status === 401) {
                        logout();
                    }
                },
            },
            mutations: {
                onError: (err) => {
                    if (authenticated && err.response?.status === 401) {
                        logout();
                    }
                },
            },
        },
    });

    return (
        <QueryClientProvider client={queryClient}>
            <div className="app">
                <Header />
                <Routes>
                    {/* NOTE: Routes are provided in both Dutch and English. This decision was made because the client requested a change to Dutch at the last moment, and to avoid broken links. The dual language setup can be reviewed and potentially removed in a few months. */}

                    {/* Default */}
                    <Route path="/" element={<Home />} />

                    {/* Available for both */}
                    <Route path="/anoniem-onderzoek/:token" element={<Campaign anonymous={true} />} />
                    <Route path="/onderzoek/:email/:token" element={<Campaign />} />
                    <Route path="/onderzoek/:token" element={<Campaign />} />
                    <Route path="/unsubscribe" element={<RequestUnsubscribe />} />
                    <Route path="/uitschrijven" element={<RequestUnsubscribe />} />
                    <Route path="/unsubscribe/:payload" element={<Unsubscribe />} />
                    <Route path="/uitschrijven/:payload" element={<Unsubscribe />} />
                    <Route path="/p/v/:payload" element={<VerifyPhone />} />
                    <Route path="/email/verify/:payload" element={<VerifyEmail />} />
                    <Route path="/email/verifieer/:payload" element={<VerifyEmail />} />

                    {/* Unauthenticated only */}
                    <Route path="/forgot-password" element={useUnauthenticatedRoute(<ForgotPassword />)} />
                    <Route path="/wachtwoord-vergeten" element={useUnauthenticatedRoute(<ForgotPassword />)} />
                    <Route path="/reset-password" element={useUnauthenticatedRoute(<ResetPassword />)} />
                    <Route path="/wachtwoord-resetten" element={useUnauthenticatedRoute(<ResetPassword />)} />
                    <Route path="/create-password" element={useUnauthenticatedRoute(<CreatePassword />)} />
                    <Route path="/wachtwoord-aanmaken" element={useUnauthenticatedRoute(<CreatePassword />)} />
                    <Route path="/register" element={useUnauthenticatedRoute(<Register />)} />
                    <Route path="/registreren" element={useUnauthenticatedRoute(<Register />)} />
                    <Route path="/login" element={useUnauthenticatedRoute(<Login />)} />
                    <Route path="/inloggen" element={useUnauthenticatedRoute(<Login />)} />

                    {/* Authenticated only */}
                    <Route path="/profile" element={useAuthenticatedRoute(<Profile />, <Navigate to="/login" />)} />
                    <Route path="/profiel" element={useAuthenticatedRoute(<Profile />, <Navigate to="/login" />)} />

                    {/* Catch all */}
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </div>
        </QueryClientProvider>
    );
}

export default App;
